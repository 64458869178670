import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import axios from "axios";

axios.defaults.baseURL = "https://backendforms.ccivitas.mx";

export class App extends Component {
  constructor() {
    super();
    this.state = {
      errorMessage: "",
      data: [],
      user: "",
      password: "",
      loading: false,
      keys: [],
      page: "allData",
      allUsers: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, errorMessage: "" });
    const userData = {
      user: this.state.email,
      password: this.state.password,
    };

    axios
      .post("/main/fetchData", userData)
      .then((res) => {
        this.setState({ data: res.data });
        let keys = Object.keys(res.data);
        this.setState({ keys: keys });

        let allUsers = [];
        this.state.keys.forEach((key) => {
          this.state.data[key].map((small, i) => {
            let object = {};
            small.map((col) => {
              if (col["inputName"] === "WhatsApp") {
                object["WhatsApp"] = col["inputValue"].trim().replace(/\s/g, '').substr(-10);
              }
              if (
                col["inputName"] === "Nombre" ||
                col["inputName"] === "Nombre y apellido." ||
                col["inputName"] === "Nombre y apellido"
              ) {
                object["Nombre"] = col["inputValue"];
              }
              if (col["inputName"] === "Correo electronico" || col["inputName"] === "Correo Electrónico" || col["inputName"] === "correo electronico" ||  col["inputName"] === "correo electrónico") {
                object["Correo electronico"] = col["inputValue"];
              }
              if (col["inputName"] === "Edad") {
                object["Edad"] = col["inputValue"];
              }
              if (col["inputName"] === "Entidad Ej. Nuevo León") {
                object["Estado"] = col["inputValue"];
              }
            });
            if (
              !allUsers.find((user) => user["WhatsApp"] === object["WhatsApp"])
            ) {
              allUsers.push(object);
            } else {
              console.log(object["WhatsApp"]);
            }
          });
        });

        this.setState({ allUsers: allUsers });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.login) {
          this.setState({ errorMessage: err.response.data.login });
        } else {
          this.setState({ errorMessage: "Sucedio un error inesperado" });
        }
      });

    this.setState({ loading: false });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  changeSorting = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    let newOrdered = this.state.allUsers
    if (event.target.value === "Juan Manuel"){
      newOrdered.sort(function(a,b) {return (Object.keys(a).length > Object.keys(b).length) ? 1 : (( Object.keys(b).length > Object.keys(a).length) ? -1 : 0);} );
    } else {
      newOrdered.sort(function(a,b) {return (a[event.target.value] === undefined || a[event.target.value] > b[event.target.value]) ? 1 : (( b[event.target.value] === undefined || b[event.target.value] > a[event.target.value]) ? -1 : 0);} );
    }
    this.setState({allUsers: newOrdered})
  };

  cambiarPagina = (event) => {
    event.preventDefault();
    if (this.state.page === "allData") {
      this.setState({ page: "summary" });
    } else {
      this.setState({ page: "allData" });
    }
  };

  render() {
    let form = (
      <Container
        style={{ marginTop: "100px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Row>
          <Col xs={12} style={{ width: "50vw" }}>
            <h1>Inicio de sesión</h1>
            <Form
              noValidate
              onSubmit={(e) => this.handleSubmit(e)}
              style={{ marginTop: "40px" }}
            >
              <Form.Group controlId="email" className="inputLogin">
                <Form.Label>Correo</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  placeholder="Enter email"
                />
              </Form.Group>

              <Form.Group
                controlId="password"
                className="inputLogin"
                style={{ marginTop: "30px" }}
              >
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  placeholder="Enter email"
                />
              </Form.Group>
              <Button
                type="submit"
                onClick={(e) => this.handleSubmit(e)}
                style={{ marginTop: "30px", width: "100%" }}
                disabled={this.state.loading}
              >
                Ingresar
              </Button>
              {this.state.errorMessage ? (
                <Alert variant="danger" className="inputLogin">
                  {this.state.errorMessage}
                </Alert>
              ) : (
                ""
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    );

    let data = (
      <Container style={{ marginTop: "20px" }} fluid>
        <Row>
          <Col xs={12}>
            <Button onClick={(event) => this.cambiarPagina(event)}>
              {this.state.page === "allData"
                ? "Cambiar a resumen"
                : "Cambiar a todos los datos"}
            </Button>
          </Col>
        </Row>
        {this.state.keys.map((key) => {
          return (
            <Row style={{ marginTop: "20px" }}>
              <Col xs={12}>
                <h5>{key}</h5>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {this.state.data[key][0].map((col) => {
                        return <th>{col["inputName"]}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data[key].map((small, i) => (
                      <tr>
                        {small.map((col) => {
                          return <td>{col["inputValue"]}</td>;
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          );
        })}
      </Container>
    );

    let summary = (
      <Container style={{ marginTop: "20px" }} fluid>
        <Row>
          <Col xs={6}>
            <Button onClick={(event) => this.cambiarPagina(event)}>
              {this.state.page === "allData"
                ? "Cambiar a resumen"
                : "Cambiar a todos los datos"}
            </Button>
          </Col>
          <Col xs={6}>
          <Form.Select aria-label="Default select example" onChange={this.changeSorting} name="orderedSummary">
            <option value="Nombre">Nombre</option>
            <option value="WhatsApp">Teléfono</option>
            <option value="Correo electronico">Correo electronico</option>
            <option value="Edad">Edad</option>
            <option value="Estado">Estado</option>
            <option value="Estado">Juan Manuel</option>
          </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover style={{ marginTop: "20px" }}>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Teléfono</th>
                  <th>Correo electronico</th>
                  <th>Edad</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {this.state.allUsers.map((person) => (
                  <tr>
                    <td>{person["Nombre"]}</td>
                    <td>{person["WhatsApp"]}</td>
                    <td>{person["Correo electronico"]}</td>
                    <td>{person["Edad"]}</td>
                    <td>{person["Estado"]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );

    let onceLoggedIn = this.state.page === "allData" ? data : summary;

    return <div>{this.state.keys.length > 0 ? onceLoggedIn : form}</div>;
  }
}

export default App;
